:root {
  --headline-color: #333;
  --body-color: #555;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans Caption', sans-serif;
}

h1, h2 {
  font-family: 'Raleway';
  color: var(--headline-color);
  -webkit-font-smoothing: antialiased;
}

p {
  font-family: 'Lato';
  color: var(--body-color);
  -webkit-font-smoothing: antialiased;
}


