.footer-container {
    background-color: #2b2b2b;
    padding: 40px 20px 20px;
    text-align: center;
}

.footer-description {
    font-family: 'Raleway';
    font-size: 15px;
    font-weight: 300;
    color: #fff;
    -webkit-font-smoothing: antialiased;
}

.footer-description-span {
    font-family: 'Raleway';
    color: #fd534c;
    -webkit-font-smoothing: antialiased;
}