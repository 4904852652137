.navbar {
    background: #d3d3d3;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    transition: 0.8s all ease;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 64px;
    z-index: 1;
    width: 100%;
    padding: 0 24px; 
    max-width: 1100px;
}

.navbar-logo-r {
    color: #999;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    font-family: 'PT Sans Caption', sans-serif;
    text-transform: uppercase;
    display: none;
}

.mobile-icon {
    display: block;
    position: absolute;
    top: -4px;
    right: 0;
    transform: translate(-100%, 55%);
    font-size: 1.8rem;
    cursor: pointer;
}

.nav-menu {
    display: none;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
}

.nav-item {
    height: 70px;    
}

.nav-links {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    color: #999;
    font-family: 'PT Sans Caption', sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    letter-spacing: 0.15px;
}

.nav-links.active {
    border-bottom: 3px solid #ba332e;
}

.nav-links:hover {
    color: #fff;
}

.nav-btn {
    display: none;
    align-items: center;
}

.nav-btn-link {
    border-radius: 50px;
    background: #ba332e;
    white-space: nowrap;
    padding: 10px 22px;
    font-size: 14px;
    color: #f1f1f1;
    font-size: 14px; 
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    text-transform: uppercase;
    margin-right: 20px;
}

.nav-btn-link:hover {
    background: #fff;
    color: #222;   
}

@media (min-width: 768px) {
    .mobile-icon {
        display: none;
    }
    .nav-menu,
    .nav-btn {
        display: flex;
    }
    .navbar-container,
    .navbar,
    .nav-item {
        height: 76px;
    }
    .nav-links,
    .nav-btn-link {
        font-size: 15px;
    }
}

@media (min-width: 960px) {
    .navbar {
        transition: none;
    }
}