.about-container {
    background-color: #d3d3d3;   
}

.about-wrapper {
    padding: 45px 20px;
}

.about-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
}

.about-headline {
    font-weight: 300;
    font-size: 28px;
    text-transform: capitalize;
    border-bottom: 1px solid #b9b9b9;
    padding: 0 0 20px 0;
    margin-bottom: 40px;
    width: 50%;
}

.about-description {
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 0.25px;
}

.about-img {
    width: 200px;
    border-radius: 50%;
    margin-bottom: 35px;
    max-width: 540px;
}

@media (min-width: 768px) {
    .about-row {
        width: 50%;
    }
    .about-headline {
        font-size: 30px;
    }
}

