.sidebar-container {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #0d0d0d;
    display: grid;
    align-items: center;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: 1;
    top: 0;
}

.hide-sidebar-container {
    opacity: 0;
    top: -100%;
}

.sidebar-close-icon {
    color: #999;
}

.sidebar-icon {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent; 
    font-size: 2rem;
    cursor: pointer;
    outline: none;
}

.sidebar-wrapper {
    color: #fff;
}

.sidebar-menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 55px);
    text-align: center;
}

.sidebar-link-s {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.sidebar-link-s:hover {
    color: #ba332e; 
}

.side-btn-wrap {
    display: flex;
    justify-content: center;
}

.sidebar-link-r {
    border-radius: 50px;
    background: #ba332e;
    white-space: nowrap;
    padding: 16px 64px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1px;
}

.sidebar-link-r:hover {
    background: #fff;
    color: #010606;
}

@media(min-width: 375px) {
    .sidebar-menu {
        grid-template-rows: repeat(5, 60px);
    }
}
