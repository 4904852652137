.hero-container {
    background: url(../images/hero-background-opt-mob.jpg) no-repeat center top / cover;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    /*background-position: center center;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;*/
}

.hero-background {
    text-align: center;
}

.hero-header {
    font-size: 30px;
    color: #fff;
    font-family: 'PT Sans Caption', sans-serif;
    letter-spacing: 2.5px;
    font-weight: 700;
}

.hero-subheader {
    font-family: 'PT Sans Caption', sans-serif;
    font-size: 12.5px;
    color: #ba332e;
    letter-spacing: 6.5px;
    font-weight: 700;
}

@media(min-width: 375px) {
    .hero-container {
        min-height: 600px;
    }
    .hero-header {
        font-size: 36px;
        letter-spacing: 2.5px;
    }
    .hero-subheader {
        font-size: 16px;
        letter-spacing: 7px;
    }
}


@media(min-width: 415px) {
    .hero-container {
        background: url(../images/hero-background-opt-tab.jpg) no-repeat center top / cover;
    }
}

@media(min-width: 768px) {
    .hero-container {
        min-height: 700px;
        background: url(../images/hero-background-opt-desk.jpg) no-repeat center top / cover;
    }
    .hero-header {
        font-size: 68px;
        letter-spacing: 5px;
    }
    .hero-subheader {
        font-size: 37.5px;
        letter-spacing: 9px;
    }
}