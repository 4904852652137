.contact-wrapper {
    padding: 40px 20px;
    max-width: 1100px;
    margin: 0 auto;
}

.contact-headline {
    font-weight: 300;
    font-size: 28px;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.contact-container ul {
    list-style: none;
}

.contact-li {
    font-weight: 300;
    line-height: 29px;
}

.contact-a {
    display: flex;
    align-items:center;
    text-decoration: none;
    cursor: pointer;
    width: 200px;
}

.contact-p {
    margin-left: 7.5px;
    transition: all 0.2s ease-in-out;
    letter-spacing: 0.15px;
  
}

.contact-p:hover {
    color: #ba332e;
}

@media(min-width: 768px) {
    .contact-wrapper {
        padding: 36px 20px;
    }
    .contact-row {
        padding-left: 1rem;
    }
    .contact-li {
        line-height: 26px;
    }
}